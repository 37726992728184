import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import "../layouts/Primary/styles/skin/templates/_location.scss"
import Widget from "../components/widgets"
import TeamBlueContent from "../components/team/detail"
import HeroMazeVideo from "../components/hero-maze-video"
import LatestJobs from "../components/LatestJobs"
import QuoteBanner from "../components/quote-banner"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface ITeamDetail {
  title: string
  slug: string
  seoTitle: string
  seoDescription: string
  heroTagline: string
  heroHeading: string
  heroContent: any
  heroButton: any
  heroImage: {
    fixed: any
  }

  blueSectionHeading: string
  blueSectionContent: any
  blueSectionImage: {
    fluid: any
  }

  latestRolesHeading: string
  latestRolesDescription: any
  noLatestRolesDescription: string
  noLatestRolesButton: any

  quoteAuthor: string
  quoteContent: any
  quoteImage: {
    fluid: any
  }

  regionAboveJobs: Array<any>
  bottomRegion: Array<any>
}

export interface ITeamDetailProps {
  contentfulCareersTeam: ITeamDetail
  allLever: {
    nodes: Array<any>
  }
}

interface ITeamDetailContext {
  id: string
  slug: string
  locale: string
  teams: Array<string>
}

const TeamDetail: React.FC<PageProps<ITeamDetailProps, ITeamDetailContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersTeam.seoTitle || data.contentfulCareersTeam.title,
    description: data.contentfulCareersTeam.seoDescription,
  }

  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout seoData={seoData}>
        <HeroMazeVideo
          h4={data.contentfulCareersTeam.heroTagline}
          heading={data.contentfulCareersTeam.heroHeading}
          content={data.contentfulCareersTeam.heroContent}
          image={data.contentfulCareersTeam.heroImage}
          heroButton={data.contentfulCareersTeam.heroButton}
        />
        <TeamBlueContent
          heading={data.contentfulCareersTeam.blueSectionHeading}
          content={data.contentfulCareersTeam.blueSectionContent}
          image={data.contentfulCareersTeam.blueSectionImage}
        />
        {data.contentfulCareersTeam.regionAboveJobs &&
          data.contentfulCareersTeam.regionAboveJobs.map(widgetData => <Widget {...widgetData} key={widgetData.title} />)}
        <LatestJobs
          heading={data.contentfulCareersTeam.latestRolesHeading}
          content={data.contentfulCareersTeam.latestRolesDescription}
          jobs={data.allLever.nodes}
          contentNoJobs={data.contentfulCareersTeam.noLatestRolesDescription}
          buttonNoJobs={data.contentfulCareersTeam.noLatestRolesButton}
          isTeamPage={true}
          mainHeading={data.contentfulCareersTeam.heroHeading}
          withPagination
        />
        <QuoteBanner
          quoteContent={data.contentfulCareersTeam.quoteContent}
          quoteAuthor={data.contentfulCareersTeam.quoteAuthor}
          quoteImage={data.contentfulCareersTeam.quoteImage}
        />

        {data.contentfulCareersTeam.bottomRegion &&
          data.contentfulCareersTeam.bottomRegion.map(widgetData => <Widget {...widgetData} key={widgetData.title} />)}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />

    </PathContext.Provider>
  )
}

export default TeamDetail

export const query = graphql`
  query($id: String!, $teams: [String!]!) {
    allLever(filter: { categories: { team: { in: $teams } } }, sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...JobPosting
      }
    }
    contentfulCareersTeam(id: { eq: $id }) {
      id
      title
      slug
      seoTitle
      seoDescription
      heroTagline
      heroHeading
      heroButton {
        ...ContentfulLink
      }
      heroContent {
        ...RichTextField
      }
      heroImage {
        fluid: gatsbyImageData(placeholder: NONE,, width: 640, height: 400, quality: 100, formats: [AUTO, WEBP])        
        title
      }

      blueSectionHeading
      blueSectionContent {
        ...RichTextField
      }
      blueSectionImage {
        fluid: gatsbyImageData(placeholder: NONE, width: 2048, height: 1500, quality: 100, formats: [AUTO, WEBP])
      }

      regionAboveJobs {
        ...ComponentCTA
      }

      latestRolesHeading
      latestRolesDescription {
        ...RichTextField
      }
      noLatestRolesDescription
      noLatestRolesButton {
        ...ContentfulLink
      }

      quoteContent {
        ...RichTextField
      }
      quoteAuthor
      quoteImage {
        fixed: gatsbyImageData(layout: FIXED width: 757 quality: 100 formats: [AUTO, WEBP])         
        fluid: gatsbyImageData(quality: 100 formats: [AUTO, WEBP])
        
        title
      }

      bottomRegion {
        ...ComponentCTA
      }
    }
  }
`
