import React from "react"
import RichTextRenderer from "../../../elements/RichTextRenderer"
import "./style.scss"

interface ITeamBlueContent {
  heading: string
  content: any
  image: { fluid: any; title?: string }
}

const TeamBlueContent: React.FC<ITeamBlueContent> = ({ heading, content, image }) => {
  return (
    <div className="blue-content-team">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-11">
            <h2>{heading}</h2>
          </div>
          <div className="col-lg-11">{content && <RichTextRenderer json={content} />}</div>
        </div>
      </div>
      <div className="imageContainer">
        {image && <img src={image?.fluid?.images?.fallback?.src} alt={image.title || ""} className="img-fluid" />}
      </div>
    </div>
  )
}

export default TeamBlueContent
