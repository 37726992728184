import React, { useState } from "react"
import RichTextRenderer from "../../elements/RichTextRenderer"
import { GatsbyImage } from "gatsby-plugin-image"
import PlaySVG from "../SVGs/play.inline.svg"
import ModalVideo from "../../elements/ModalVideo"

interface HeroMazeDefault {
  heading: any
  subheading?: string
  h4?: string
  content: any
  image: any
  heroButton?: any
}

const HeroMazeVideo: React.FC<HeroMazeDefault> = ({
  heading,
  subheading,
  h4,
  content,
  image,
  heroButton,
}) => {
  const [modalShow, setModalShow] = useState(false)

  const openModal = (e: any) => {
    e.preventDefault()
    setModalShow(true)
  }

  const hideModal = () => {
    setModalShow(false)
  }

  return (
    <section className="hero hero-video">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-6 hero-video-arrow">
            {heroButton && heroButton?.url ? (
              <>
                <a className="js-video" href="#" onClick={openModal}>
                  <GatsbyImage
                    image={image.fluid}
                    alt={image.title || ""}
                    className="img-fluid hero-image-angle"
                  />
                  <span className="play-icon">
                    <PlaySVG className="icon-sprite" />
                    <div className="sr-only">{heroButton?.label}</div>
                  </span>
                </a>
                <ModalVideo
                  show={modalShow}
                  onHide={hideModal}
                  video={heroButton?.url}
                />
              </>
            ) : (
              <GatsbyImage
                image={image.fluid}
                alt={image.title || ""}
                className="img-fluid hero-image-angle"
              />
            )}
          </div>
          <div className="col-lg-6 hero-video-content">
            {subheading && <p className="sub-heading">{subheading}</p>}
            {h4 && <p className="h4">{h4}</p>}
            {heading ? (
              typeof heading === "string" ? (
                <h1 className="hero-heading">{heading}</h1>
              ) : (
                <RichTextRenderer json={heading} noContainer />
              )
            ) : null}
            {content && <RichTextRenderer json={content} noContainer />}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroMazeVideo
